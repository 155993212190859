export const environment = {
    url: 'https://hostssvc02.snt-mkt-automation.com/api',
    urlMicro: 'https://hostssvc01.snt-mkt-automation.com/api',
    urlTeste: 'http://localhost:72/api',
    websocket: 'wss:hostssvc03.snt-mkt-automation.com',
    production: false,
    idBloqueio: 2985,
    VERSION: '4.5.211.2024-10-11-15-37-56',
    SECRET_KEY: '$2y$10$SM9aIjPVrh1OHYSRfwtcfuB8vgMSK.76rcHuwekEIKypUN.9bgFFK',
    PIS_URL: 'https://hopis.snt-mkt-automation.com/',
    templateMenuUser: '/src/app/core/layout/components/navbar/components/menu-user/menu-user.component.html'
};

export const adAgency: string = 'StormX';
